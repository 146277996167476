import React, { memo } from 'react'
// import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'justify',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',

    color: 'rgba(255,255,255,.7)',
    '& strong': {
      fontWeight: '400',
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em',
    },
  },
}))
const ContentText = ({ children }) => {
  const classes = useStyles()
  return <Box className={classes.root}>{children}</Box>
}
export default memo(ContentText)
